<script setup>
import { onBeforeMount } from 'vue'

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(m,key,value) {
      vars[key] = value;
    });
  return vars;
}

var urlVars = getUrlVars();

var din_params_735 = {
  'refid': urlVars['subid'] || ''
};

onBeforeMount(() => {
  const tapStoneIo = document.createElement('script')
  tapStoneIo.async = true
  tapStoneIo.src = '//adserv.convertingtraffic.com/www/delivery/asyncjs.php'
  document.getElementsByTagName('head')[0].appendChild(tapStoneIo)
})
</script>

<template>
  <div class="section">
    <div class="thank-you-container">
      <div class="sub-text-container">
        <h1>Based on your answers you could be
          <br/>
          <span class="green">eligible for relief services</span>
        </h1>
        <h3>Here’s what to expect next:</h3>
      </div>

      <div class="content-wrapper">
        <div class="col">
          <div class="step-container">
            <div class="step-wrapper">
              <div class="number">1</div>
              <h2>CONFIRMATION</h2>
            </div>
            <p class="step-text">We call you to verify your information, introduce you to your debt consultant and get any further details about your type of debt.</p>
          </div>
          <div class="step-container">
            <div class="step-wrapper">
              <div class="number">2</div>
              <h2>Consultant follow-up</h2>
            </div>
            <p class="step-text">Your consultant makes an assessment based on the information you provide him or her.</p>
          </div>
          <div class="step-container">
            <div class="step-wrapper">
              <div class="number">3</div>
              <h2>Relief service activation</h2>
            </div>
            <p class="step-text" >Your service becomes active and your consultant provides you with your debt relief plan.</p>
          </div>
          <div class="step-container">
            <div class="step-wrapper">
              <div class="number">4</div>
              <h2>Become debt free</h2>
            </div>
            <p class="step-text">Enjoy a life that is free from the burdens of debt!</p>
          </div>
        </div>
        <div class="col">
          <a class="box" target="_blank" href="https://www.ikd92trk.com/6P5Q9H/BP658/">
            <div class="wrapper">
              <div class="img-container autoaccidentteam-container">
                <img src="../assets/images/car_Accident_compressed.jpg" alt="">
              </div>
              <div class="inner-text-container">
                <h3 class="box-title">Were you in a Motor Vehicle Accident?</h3>
                <p class="card-sub-text">Get your case value and take the first step to getting a settlement for your accident.</p>
                <button>
                  Get my free claim review
                </button>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="col">
          <ins data-revive-zoneid="735" data-revive-id="e2512d2b571194f68f176aec0585f389"></ins>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section {
  min-height: calc(100vh - 367px);
  margin: 20px;
  .thank-you-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 1000px;
    margin: auto;
    border-radius: 8px;
    padding: 20px;
    text-align: center;

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 40px;
    }

    .sub-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .content-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .step-container {
      max-width: 340px;
      margin-bottom: 10px;
    }
    .step-text {
      text-align: left;
      margin-left: 50px;
    }
    .box {
      .wrapper {
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        max-width: 370px;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: all;
        &:hover {
          -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
          box-shadow: 0 22px 14px -14px rgba(0,0,0,0.4);
          -webkit-box-shadow: 0 22px 14px -14px rgba(0,0,0,0.4);
          -moz-box-shadow: 0 22px 14px -14px rgba(0,0,0,0.4);
        }
        .img-container img {
          width: 100%;
          max-width: 370px;
        }
        .box-title {
          font-size: 28px;
          text-align: center;
          color: #00afba;
        }

        .inner-text-container {
          padding: 10px;
          color: #666666;
          min-height: 130px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          p {
            color: inherit;
            margin-top: 8px;
            font-size: 16px;
            line-height: 1.3rem;
            text-decoration: none;
            text-align: center;
          }
          button {
            background-color: #00afba;
            cursor: pointer;
            padding: 15px;
            font-size: 16px;
            color: white;
            border-radius: .25rem;
            font-weight: 700;
            border: none;
            margin: 20px auto 20px auto;
            max-width: 320px;
            width: 100%;
            transition: background-color .3s;
            &:hover {
              box-shadow: 0 1px 7px -3px #000000;
              background-color: #00bfcb;
            }
          }
        }
      }
      min-height: 250px;
      h2 {
        text-align: center;
        font-weight: bold;
        font-size: 38px;
        line-height: 3.5rem;
      }
      p {
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        line-height: 2.2rem;
      }
    }
    .advertisement {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 20px;
      align-items: center;
      .ads {
        position: relative;
        .spinner {
          animation: rotate 2s linear infinite;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -30px 0 0 -30px;
          width: 60px;
          height: 60px;
          z-index: 0;
          .path {
            stroke: hsl(358, 79%, 48%);
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
          }
        }
      }
    }
    .advertisement a img {
      border-radius: 5px;
      max-width: 500px;
      max-height: 500px;
    }
    .free-claim-review {
      margin: 0 auto;
      button {
        background-color: #b00101;
        cursor: pointer;
        padding: 16px;
        font-size: 16px;
        margin: 16px 0;
        color: white;
        border-radius: .25rem;
        font-weight: 700;
        border: none;
        text-transform: uppercase;
        &:hover {
          background-color: #b00101;
          box-shadow: 0 1px 7px -3px #000000;
        }
      }
    }
    h1 {
      color: #666666;
      font-family: sans-serif;
      margin-bottom: 30px;
      font-size: 32px;
      max-width: 680px;
      .green {
        color: #00afba;
      }
    }
    p {
      color: #666666;
      font-family: sans-serif;
      max-width: 700px;
      margin: 0 auto 20px auto;
    }
    h3 {
      color: #666666;
      font-family: sans-serif;
      margin-bottom: 20px;
      font-size: 26px;
    }
    .step-wrapper {
      display: flex;
      .number {
        border: 1px solid;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #00afba;
        border-radius: 50%;
        color: white;
        font-family: sans-serif;
        font-size: 25px;
      }
      h2 {
        font-family: sans-serif;
        color: #00afba;
        line-height: 1.7;
        margin-left: 10px;
        text-transform: uppercase;
        font-size: 18px;
        display: flex;
        align-items: center;
      }
    }
  }
}

//iPad Pro Styling
@media (min-width: 1024px) and (min-height: 1366px) {
  .section .thank-you-container {
    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 32px;
    }

    .step-text {
      font-size: 22px;
    }

    .box .wrapper {
      .box-title {
        font-size: 28px;
      }
      .inner-text-container p {
        font-size: 22px;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@media screen and (max-width: 920px) {
  .section .thank-you-container {
    .col {
      margin: 20px;
    }

    .step-text {
      margin-left: 0;
    }

    .step-wrapper .number {
      width: 34px;
      height: 34px;
    }

    .content-wrapper {
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }
  }
}

@media (max-width: 500px) {
  .section .thank-you-container {
    .col {
      margin: 0;
    }

    .advertisement {
      margin: 20px 0;
    }

    h1 {
      font-size: 28px;
    }

    .step-wrapper {
      h2 {
        line-height: 1.3;
        font-size: 17px;
      }

      .number {
        width: 30px;
        height: 30px;
        text-align: center;
        min-width: 30px;
      }
    }
  }
}

@media (max-width: 375px) {
  .section {
    margin: 0;
    .thank-you-container {
      .sub-text-container {
        align-items: flex-start;
      }
      h1 {
        font-size: 25px;
      }

      h3 {
        text-align: left;
        font-size: 22px;
      }

      .step-text {
        margin-left: 0;
      }
    }
  }
}
</style>
